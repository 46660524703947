// Depracation Warning
// This file is now depracted, as it does not respect sepration of concern.
// Please avoid it's usage for new feature


const MAILOOP_ROOT = process.env.REACT_APP_BACKEND_URL || "https://app.mailoop.com";

function ParamNotStringError(paramName, paramValue) {
  this.name = "ParamNotStringError";
  this.message = (`${paramName} should be a string not ${typeof (paramValue)}` || "");
}

ParamNotStringError.prototype = Error.prototype;

function ParamNotFunctionError(paramName, paramValue) {
  this.name = "ParamNotFunctionError";
  this.message = (`${paramName} should be a function not ${typeof (paramValue)}` || "");
}

ParamNotFunctionError.prototype = Error.prototype;

const domain = email => email.match(/@.+$/g) || "";

const working_time = (working_hours, working_days) => {
  const working_time = {};
  working_days.forEach(working_day => working_time[working_day] = working_hours);
  return working_time;
};

class RequestFactory {
  // fetchFn signature header => method => endpoint => payload
  constructor(userEmail, userToken = "no-token-provided", frontApp, fetchFn, exchangeIdentityToken) {
    if (typeof (userEmail) !== "string") throw new ParamNotStringError("userEmail", userEmail);
    if (typeof (userToken) !== "string") throw new ParamNotStringError("userToken", userToken);
    if (typeof (frontApp) !== "string") throw new ParamNotStringError("frontApp", frontApp);
    if (typeof (fetchFn) !== "function") throw new ParamNotFunctionError("fetchFn", fetchFn);

    this.frontApp = frontApp;
    this.fetchWH = fetchFn(this.headers(userEmail)(userToken)(exchangeIdentityToken)); // fetch with enclosed Header
    this.appRoot = this.rootEndPoint(userEmail);
  }

  headers = email => userToken => exchangeIdentityToken => ({
    'Content-Type': 'application/json',
    'X-User-Email': email,
    'X-User-Token': userToken,
    'X-User-Exchange-Identity-Token': exchangeIdentityToken
  })

  // Return the first one not undefined.
  rootEndPoint = email => MAILOOP_ROOT

  // Fetch With Header
  // Given rootEndpoint and rails uri, return the method to create the complete endpoint
  endPoints = railsUri => (routeParams) => {
    const routes = {
      api_v2_email_votes: `${this.appRoot}/api/v2/emails/${routeParams}/votes`,
      api_v2_votes: `${this.appRoot}/api/v2/votes`,
      api_v2_vote: `${this.appRoot}/api/v2/votes/${routeParams}`,
      api_v2_company_behaviors: `${this.appRoot}/api/v2/company/behaviors`,
      api_v2_tokens_google: `${this.appRoot}/api/v2/tokens/google`,
      api_v2_tokens_microsoft: `${this.appRoot}/api/v2/tokens/microsoft`,
      error_hook: `${this.zapierRoot}/hooks/catch/3058207/k95cs2/`,
      metric_hook: `${this.zapierRoot}/hooks/catch/3058207/kiofuh/`,
      api_v2_magic_link: `${this.appRoot}/api/v2/magic_link`,
      api_v2_company_version: `${this.appRoot}/api/v2/company/version`,
      api_v2_company_domains: `${this.appRoot}/api/v2/company/domains`,
      api_v2_ms_meeting_votes: `${this.appRoot}/api/v2/ms_meeting_votes`,
      api_v2_ms_meeting_postvotes: `${this.appRoot}/api/v2/ms_meetings/votes`,
      api_v2_employee_status: `${this.appRoot}/api/v2/employees/${routeParams}/status`,
      api_v2_employee_api_available: `${this.appRoot}/api/v2/employees/${routeParams}/api_available`,
      api_v2_employee_deconnexion_settings: `${this.appRoot}/api/v2/employees/${routeParams}/deconnexion_settings`,
      api_v2_employees_set_smart_deconnexion_enabled: `${this.appRoot}/api/v2/employees/${routeParams}/set_smart_deconnexion_enabled`,
    };
    return routes[railsUri];
  }

  // Payload

  emptyPayload = () => ({})
  // Payload Creator


  postVotePayload = (behavior_id, item) => {
    return {
      vote: {
        behavior_id,
        email: item
      }
    };
  }

  postVoteMeetingPayload = (behavior_id, item) => {
    return {
      vote: {
        behavior_id,
        meeting: item
      }
    };
  }

  postVotesMeetingPayload = (item) => {
    // debugger
    return {
      meeting: item
    };
  }

  metricsPayload = (app, date, user, metric, value) => ({
    app: this.frontApp,
    date,
    user,
    metric,
    value,
  })

  errorPayload = (app, date, user, errorMessage) => ({
    app,
    date,
    user,
    error: errorMessage,
  })

  googleATPayload = googleAccessToken => ({
    google_access_token: googleAccessToken,
  })

  microsoftATPayload = (exchange_identity_token, email) => ({
    exchange_identity_token,
    email
  })

  msGraphAccessPayload = access_status => ({
    status: access_status,
  })


  deconnexionSettingsPayload = (country, time_zone, working_hours, working_days, is_enabled_at_nights, is_enabled_at_weekends, is_enabled_at_vacations) => {
    // console.log("coucou")
    const json = ({
      working_time: working_time(working_hours, working_days),
      time_zone,
      country,
      is_enabled: {
        nights: is_enabled_at_nights,
        weekends: is_enabled_at_weekends,
        vacations: is_enabled_at_vacations
      }
    });
    // console.log(json)
    return json;
  }

  setSmartDeconnexionEnablePayload = is_enabled => ({
    smart_deconnexion_enabled: is_enabled,
  })

  // Method cal from the outside
  // (routeParams)(payloadArgs) => Call_the_Appropriate_api
  // request.getVotes(messageId)()
  call = method => railsUri => payloadCreator => routeParams => (...payloadArgs) => (
    this.fetchWH(method)(this.endPoints(railsUri)(routeParams))(payloadCreator(...payloadArgs))
  )

  get = this.call('get')

  post = this.call('post')

  _delete = this.call('delete')

  getBehaviors = (...args) => this.get('api_v2_company_behaviors')(this.emptyPayload)(...args)

  getDomains = (...args) => this.get('api_v2_company_domains')(this.emptyPayload)(...args)

  getVotes = (...args) => this.get('api_v2_email_votes')(this.emptyPayload)(...args)

  postVotesMeeting = (...args) => this.post('api_v2_ms_meeting_postvotes')(this.postVotesMeetingPayload)(...args)

  getVersion = (...args) => this.get('api_v2_company_version')(this.emptyPayload)(...args)

  postVote = (...args) => this.post('api_v2_votes')(this.postVotePayload)(...args)

  postVoteMeeting = (...args) => this.post('api_v2_ms_meeting_votes')(this.postVoteMeetingPayload)(...args)

  //  to-do:
  //   include attendees
  postError = (...args) => this.post('error_hook')(this.errorPayload)(...args)

  postMetric = (...args) => this.post('metric_hook')('this.metricsPayload')(...args)

  deleteVote = (...args) => this._delete('api_v2_vote')(this.emptyPayload)(...args)

  // Auth Method.
  getRailsTokenFromGoogleAccessToken = (...args) => this.post('api_v2_tokens_google')(this.googleATPayload)(...args)

  getRailsToken = this.getRailsTokenFromGoogleAccessToken

  // Accendant Compatibility
  getRailsTokenFromMicrosoftAccessToken = (...args) => this.post('api_v2_tokens_microsoft')(this.microsoftATPayload)(...args)

  // Magic Link
  getMagicLink = (...args) => this.get('api_v2_magic_link')(this.emptyPayload)(...args)

  getEmployeeStatus = (...args) => this.get('api_v2_employee_status')(this.emptyPayload)(...args)

  postMsGraphAccessStatus = (...args) => this.post('api_v2_employee_api_available')(this.msGraphAccessPayload)(...args)

  postDeconnexionSettings = (...args) => this.post('api_v2_employee_deconnexion_settings')(this.deconnexionSettingsPayload)(...args)

  postSmartDeconnexionEnabled = (...args) => this.post('api_v2_employees_set_smart_deconnexion_enabled')(this.setSmartDeconnexionEnablePayload)(...args)
}

export { RequestFactory };
