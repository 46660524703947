import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import {
  Dropdown, IDropdown, DropdownMenuItemType, IDropdownOption
} from 'office-ui-fabric-react/lib/Dropdown';
import { BaseComponent, createRef } from 'office-ui-fabric-react/lib/Utilities';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import TooltipHostUnlessMobile from '../utils/TooltipHostUnlessMobile';
import './DaysPicker.css';
import { injectSafeIntl } from '../utils/recomposeToolbox';


class ProductChoice extends Component {
  constructor(props) {
    super(props);
    const { messages } = this.props;
    const { intl: { formatMessage } } = this.props;
    this.state = {
      working_days: []
    };
  }


  render() {
    const { workingDays, handleWorkingDayChange } = this.props;
    const { intl: { formatMessage }, messages } = this.props;

    const isDaySelected = day => workingDays.includes(day);

    return (
      <div id="dayspicker">
        <p>{formatMessage(messages.workingDays)}</p>
        <div id="checkboxes">
          {[1,2,3,4,5,6,7].map(dayNumber =>
            <TooltipHostUnlessMobile 
              className="ms-font-l" 
              content={formatMessage(messages[`unitsDateDay${dayNumber}`])} 
               
              calloutProps={{ gapSpace: 0 }}>
              <Checkbox 
                checked={isDaySelected(dayNumber)} 
                onChange={(event, value) => handleWorkingDayChange(value, dayNumber)} 
              />
            </TooltipHostUnlessMobile>
            )
          }
        </div>

        <div id="days">
          <p id="day" className="ms-font-l">{formatMessage(messages.monday)}</p>
          <p id="day" className="ms-font-l">{formatMessage(messages.tuesday)}</p>
          <p id="day" className="ms-font-l">{formatMessage(messages.wednesday)}</p>
          <p id="day" className="ms-font-l">{formatMessage(messages.thursday)}</p>
          <p id="day" className="ms-font-l">{formatMessage(messages.friday)}</p>
          <p id="day" className="ms-font-l">{formatMessage(messages.saturday)}</p>
          <p id="day" className="ms-font-l">{formatMessage(messages.sunday)}</p>
        </div>
      </div>
    );
  }
}


export default injectSafeIntl(ProductChoice);
