import {
  compose, withProps, renameProp, branch, renderComponent
} from "recompose";
import { handlePromiseResolution, Loading, notifyIsApiAvailable } from "./withFetchedData";
import { pause, logProps } from "./recomposeToolbox";
import { handleApiResponse } from "./helpers";

// contain all the utils Require to comunnicate with exchange.
// This file is suppose to be React / Compose / Props free.


export const isExchangeApiAvailable = () => compose(
  withProps(props => ({ currentItemRestId: currentItemRestId(props.officeJS) })),
  withProps(props => ({
    promise:
        accessTokenPromise(props.mailbox)
          .then((response) => {
            if (response.status === "succeeded") {
              return (response.value);
            }
            throw "Can't get get Exchange access Token";
          })
  })),
  handlePromiseResolution(),
  branch((({ isLoading }) => isLoading), renderComponent(Loading)),
  renameProp("data", "exchangeAccessToken"),
  withProps(props => ({ promise: currentItemPromise(props) })),
  handlePromiseResolution(),
  renameProp("data", "exchangeCurrentItem"),
  withProps(props => ({ exchangeApiAvailable: (!!(props.exchangeCurrentItem)) }),
  notifyIsApiAvailable,
))

// Warning props binded
export const currentItemPromise = (props) => {
  const endpoint = `${props.restUrl}/v2.0/me/messages/${props.currentItemRestId}`;
  const headers = {
    method: 'GET',
    headers: new Headers({ Authorization: `Bearer ${props.exchangeAccessToken}` }),
    mode: 'cors',
    cache: 'default'
  };
  const myRequest = new Request(endpoint, headers);
  return (
    fetch(myRequest)
    .then(handleApiResponse)
    .then(response => response.json())
  )
};


export const accessTokenPromise = mailbox => new Promise((resolve, reject) => {
  mailbox.getCallbackTokenAsync(resolve, reject);
});

export const currentItemRestId = (officeJS) => {
  if (officeJS.context.mailbox.diagnostics.hostName === 'OutlookIOS') {
    return (officeJS.context.mailbox.item.itemId);
  }
  return (officeJS.context.mailbox.convertToRestId(
    officeJS.context.mailbox.item.itemId,
    officeJS.MailboxEnums.RestVersion.v2_0
  ));
};

// Add mailbox, emails,  settings from Office Js
export const officeDatas = officeJS => ({
  language: officeJS.context.displayLanguage.substring(0, 2),
  mailbox: officeJS.context.mailbox,
  email: officeJS.context.mailbox._userProfile$p$0._data$p$0._data$p$0.userEmailAddress,
  settings: officeJS.context.roamingSettings,
  restUrl: officeJS.context.mailbox.restUrl
});
