import React, { Component } from 'react';
import { handleApiResponse } from '../utils/helpers';

export default (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        domains: undefined,
        apiError: false
      };
    }

    load() {
      const {requestClient, settings} = this.props
      const storeInRoamingSettings = name => (value) => {
        settings.set(name, value);
        settings.saveAsync();
        return value;
      };

      requestClient.getDomains()()
        .then(handleApiResponse)
        .then(response => response.json())
        .then((json) => {
          const domains = json.map(domain => domain.url);
          this.setState({ domains });
          return domains;
        })
        .then(storeInRoamingSettings("domains"))
        .catch(() => this.setState({ apiError: true }));
    }

    componentDidMount() {
      const { settings } = this.props;

      if (settings.get("domains")) {
        this.setState({ domains: settings.get("domains") });
      }
      this.load();
    }

    render() {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
        />

      );
    }
  };
};
