

export default class {
  constructor(email, item, itemClass, domains) {
    this.email = email;
    this.item = item;
    this.itemClass = itemClass;

    const today = new Date();
    const dateItem = (item.itemType === "message") ? item.dateTimeCreated : (item.end || new Date('December 31, 2200 00:00:00'));
    const diff = Math.floor(today.getTime() - dateItem.getTime());
    const day = 1000 * 60 * 60 * 24;
    const days = Math.floor(diff / day);
    const itemFrom = (item.itemType === "message") ? item.from.emailAddress : item.organizer.emailAddress;
    const domainEmail = itemFrom.split("@")[1];
    this.conditions = {
      sameAuthorEmail: (item.itemType === "message") && (email === itemFrom),
      sameAuthorMeeting: (item.itemType === "appointment") && (email === itemFrom),
      externalEmail: (item.itemType === "message") && (!domains.includes(domainEmail)),
      oldItemEmail: (item.itemType === "message") && (days > 30),
      oldItemMeeting: (item.itemType === "appointment") && (days > 30),
      notYetMeeting: (item.itemType === "appointment") && (days < 0),
      notRealAppointement: (item.itemType === "message") && (itemClass.includes("IPM.Schedule.Meeting")),
    };
  }


  isFeedbackNotAllowed = () => {
    const {
      sameAuthorEmail, sameAuthorMeeting, oldItemEmail, oldItemMeeting, notYetMeeting, notRealAppointement
    } = this.conditions;
    return sameAuthorEmail || sameAuthorMeeting || oldItemEmail || oldItemMeeting || notYetMeeting || notRealAppointement;
  }

  whichConditionIsMet = () => {
    const {
      sameAuthorEmail, sameAuthorMeeting, oldItemEmail, oldItemMeeting, notYetMeeting, externalEmail, notRealAppointement
    } = this.conditions;

    const hashTable = {
      sameAuthorEmail,
      sameAuthorMeeting,
      oldItemEmail,
      oldItemMeeting,
      notYetMeeting,
      externalEmail,
      notRealAppointement
    };
    const conditionsMet = Object.keys(hashTable).filter(key => hashTable[key]);
    const conditionMet = (conditionsMet.length > 1) ? conditionsMet[0] : conditionsMet;
    return conditionMet;
  }

  feedbackNotAllowedMessage = (messages, formatMessage) => {
    const messageTable = {
      sameAuthorEmail: formatMessage(messages.feedbackNotAllowedSameAuthorEmail),
      sameAuthorMeeting: formatMessage(messages.feedbackNotAllowedSameAuthorMeeting),
      oldItemEmail: formatMessage(messages.feedbackNotAllowedOldItemEmail),
      oldItemMeeting: formatMessage(messages.feedbackNotAllowedOldItemMeeting),
      notYetMeeting: formatMessage(messages.feedbackNotAllowedNotYetMeeting),
      externalEmail: formatMessage(messages.feedbackNotAllowedExternalEmail),
      notRealAppointement: formatMessage(messages.feedbackNotAllowedNotRealAppointement)
    };
    const message = messageTable[this.whichConditionIsMet()];
    return message;
  }
}
