import React, { Component } from 'react';
import './Behavior.css';
import { Form } from 'reactstrap';
import { detect } from 'detect-browser';
import { compose, withProps, withState } from 'recompose';
import { injectSafeIntl, logProps } from '../utils/recomposeToolbox';
import TooltipHostUnlessMobile from '../utils/TooltipHostUnlessMobile';
import { withErrorBoundaryMsg } from '../utils/WithErrorBoundary';
import { isNumber } from 'util';


const classCss = (voted, family) => {
  const isClicked = (voted) ? "clicked" : "";
  return `${family} ${isClicked}`;
}



class Behavior extends Component {
  render() {
    const { family, voted, ref_name, description, name } = this.props
    return (
      <div>
        <Form 
          id="content-vote" 
          className={classCss(voted, family)} 
          onClick={this.props.handleClick}
        >
          <img style={{ width: '32px', height: '32px' }} src={`assets/${ref_name}_64.png`} alt={name}/>
          <TooltipHostUnlessMobile content={description} id="hoverDescription" calloutProps={{ gapSpace: 0 }}>
            <p className="ms-font-m">
              {' '}
              {name}
              {' '}
            </p>
          </TooltipHostUnlessMobile>
        </Form>
      </div>
    );
  }
}
export default compose(
  withErrorBoundaryMsg("Behavior"),
  logProps("bahavior"),
  withProps(props => ({
    voted: isNumber(props.vote_id)
  })),
  withState("voted", "setVoted", props => props.voted ),
  withProps(props => ({
    handleClick: (props.voted)? () => {
      props.setVoted(false)
      props.deleteVote(props.vote_id)
    }: () => {
      props.setVoted(true)
      props.postVote(props.id)
    }
  })),
)(Behavior)
