import React, { Component } from 'react';
import { handleApiResponse, fetchFn } from '../utils/helpers';
import { Try } from '../utils/recomposeToolbox';

export default (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super();
      const { settings } = props;
      this.state = {
        behaviors: settings.get("behaviors"),
        apiError: false,
        completeLocaleData: settings.get("completeLocaleData")
      };
    }

    componentDidMount() {
      this.load();
    }

    load() {
      const { requestClient, settings } = this.props
      const storeInRoamingSettings = name => (value) => {
        settings.set(name, value);
        settings.saveAsync();
        return value;
      };

      const cleanBehaviors = (rawBehaviors) => {
        const mapFamily = (family) => {
          return ({
            0: "neutral",
            1: "positive",
            "-1": "negative"
          }[family.toString()]);
        };

        return rawBehaviors
          .sort((a, b) => a.priority - b.priority)
          .sort((a, b) => b.family - a.family)
          .map(behavior => ({
            ...behavior, 
            family: mapFamily(behavior.family),
            "@option" : {
              nameTranslator: language => 
                Try (behavior => behavior.translations[language].name)(behavior) ||
                Try (behavior =>  behavior.translations["en"].name)(behavior) ||
                behavior.name ,
              descriptionTranslator: language => 
                Try (behavior => behavior.translations[language].description)(behavior) ||
                Try (behavior =>  behavior.translations["en"].description)(behavior) ||
                behavior.description ,
            }
          }))
      };

      requestClient.getBehaviors()()
        .then(handleApiResponse)
        .then(response => response.json())
        .then(cleanBehaviors)
        .then((behaviors) => {
          this.setState({ behaviors });
          return behaviors;
        })
        .then(storeInRoamingSettings("behaviors"))
        .catch(() => this.setState({ apiError: true }));
    }

    render() {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
        />
      );
    }
  };
};
