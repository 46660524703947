import React from 'react';
import Async from "react-async";
import {
  compose, branch, withProps, renderComponent, renameProp
} from 'recompose';
import { Row } from 'reactstrap';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import axios from 'axios';
import { composeLog } from './recomposeToolbox';

export const Loading = () => {
  return (
    <Row type="flex" justify="space-around" align="middle" style={{ marginTop: 250 }}>
      <Spinner size={SpinnerSize.large} ariaLive="assertive" />
    </Row>
  );
};
export const fetchPromise = (params = {}) => {
  const {
    url, method = "GET", email = "", exchangeIdentityToken = "" ,userToken = "", body = {}
  } = params;
  const headers = {
    'Content-Type': 'application/json',
    'X-User-Email': email,
    'X-User-Exchange-Identity-Token': exchangeIdentityToken
  };
  let request = {
    headers,
    method,
    mode: 'cors',
    cache: 'default',
  };
  request = (method == "POST") ? { ...request, body: JSON.stringify(body) } : { ...request };
  return (fetch(url, request));
};
export const handlePromiseResolution = () => WrappedComponent => props => (
  <Async promiseFn={() => props.promise}>
    {({ data, error, isLoading }) => (
      <WrappedComponent {...props} data={error ? undefined : data} error={error} isLoading={isLoading} />
    )}
  </Async>
);

export const notifyIsApiAvailable = compose(
  withProps(props => ({ debug: navigator.userAgent })),
  withProps((props) => ({
      promise: fetchPromise({
        url: `${props.backendRootUrl}/api/v2/employees/${props.email}/api_available`,
        email: props.email,
        userToken: props.token,
        exchangeIdentityToken: props.exchangeIdentityToken,
        method: "POST",
        body: ({ status: props.exchangeApiAvailable })
      })
    })),
  handlePromiseResolution(),
  branch((({ isLoading }) => isLoading), renderComponent(Loading)),
);

const relogOn401 = (response, relogFn  )=> {
  if(response.status === 401) {
    relogFn()
    return (response)
  } else {
    return(response)
  }
}

export const handlePromiseResolutionWithLoading = ({ valueName = "data"}) => compose(
  handlePromiseResolution(),
  branch(({ isLoading }) => isLoading, renderComponent(Loading)),
  renameProp("data", valueName)
)

export const fetchEmployeeStatus = () => compose(
  withProps(props => ({
    promise: fetchPromise({
      url: `${props.backendRootUrl}/api/v2/employees/${props.email}/status`,
      email: props.email,
      userToken: props.token,
      exchangeIdentityToken: props.exchangeIdentityToken,
      method: "GET",
    })
    .then(response => relogOn401(response, props.relogUser))
    .then(response => response.json())
  })),
  handlePromiseResolution(),
  branch((({ isLoading }) => isLoading), renderComponent(Loading)),
  renameProp("data", "employeeStatus"),
);
