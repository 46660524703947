import React, { Component } from 'react';
import Joyride from 'react-joyride';
import './Onboarding.css';
import { EVENTS } from 'react-joyride/es/constants';
import compose from 'ramda/es/compose';
import { injectSafeIntl } from '../utils/recomposeToolbox';
import { withErrorBoundaryMsg } from '../utils/WithErrorBoundary';


const options = {
  arrowColor: '#C55A11',
  primaryColor: '#C55A11',
};


class Onboarding extends Component {
  constructor(props) {
    super(props);
    const { messages } = this.props;
    const { intl: { formatMessage } } = this.props;

    this.state = {
      run: true,
      userOnboard: this.props.settings.get("userOnboard"),
      stepIndex: 0,
      steps: [
        {
          target: '.onboarding-vote',
          content: <div>
            <h3>
              {' '}
              {formatMessage(messages.onBoardingFirstSlide)}
              {' '}
            </h3>
          </div>,
          placement: 'bottom',
        },
        {
          target: ".onboarding-vote",
          content: <div>
            <h3>
              {' '}
              {formatMessage(messages.onBoardingSecondSlide)}
              {' '}
            </h3>
                   </div>,
          placement: 'bottom',
          offset: 1,
        },
        {
          target: '.onboarding-vote',
          content: <div>
            <h3>
              {' '}
              {formatMessage(messages.onBoardingThirdSlide)}
              {' '}
            </h3>
                   </div>,
          placement: 'bottom',
        },
        {
          target: '.onboarding-dashboard',
          content: <div>
            <h3>
              {' '}
              {formatMessage(messages.onBoardingFourthSlide)}
              {' '}
            </h3>
                   </div>,
          placement: 'bottom',
        },
      ]
    };
  }


  componentDidMount() {
    this.setState({ run: true });
  }


    callback = (tour) => {
      const { action, index, type } = tour;

      if (type === EVENTS.TOUR_END) {
        this.setState({ run: false });
        this.props.settings.set("userOnboard", true);
        this.props.settings.saveAsync();
      }
    };

    render () {
      const { steps, run } = this.state;
      const { intl: { formatMessage }, messages } = this.props;

      return (
        <div className="app">
          <Joyride
            continuous
            scrollToFirstStep
            showProgress
            showSkipButton
            run={run}
            steps={steps}
            styles={{ options }}
            disableBeacon={false}
            callback={this.callback}
            locale={{
              skip: formatMessage(messages.onBoardingSkip), next: ">", back: "<", last: "4"
            }}
          />
        </div>
      );
    }
}
export default compose(
  withErrorBoundaryMsg("Onboarding"),
  injectSafeIntl,
)(Onboarding);
