import React from 'react';
import Rollbar from 'rollbar';
import omitBy from 'lodash.omitby';
import { pick } from 'ramda';


const whiteListedProps = [
  "ref",
  "votes",
  "apiError",
  "requestFactory",
  "behaviors",
  "completeLocaleData",
  "domains",
  "updateBehaviors",
  "userOnboard",
  "isLoading",
  "language",
  "email",
  "restUrl",
  "backendRootUrl",
  "currentItemRestId",
  "employeeStatus",
  "is_active",
  "currently_allowed_scopes",
  "smart_deconnexion_set",
  "smart_deconnexion_enabled",
  "magicLink",
];

const isJsonStringifyable = (object) => {
  try {
    JSON.stringify(object);
    return (true);
  } catch {
    return (false);
  }
};

export const withErrorBoundaryMsg = (errorCatcherId = "Not defined") => (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        error: {},
        info: "",
      };
    }

    componentDidCatch(error, info) {
      const rollbar = new Rollbar({
        accessToken: '25f32ac62e48448c941a805d47ea6827',
        captureUncaught: true,
        captureUnhandledRejections: true
      });
      const props = this.props;
      const exportableProps = pick(whiteListedProps)(omitBy(props, prop => !isJsonStringifyable(prop)));

      rollbar.error({
        errorCatcherId,
        info,
        error,
        errorCatcherProps: exportableProps,

      });
      if (props.console && props.console.printJsObject) {
        props.console.printJsObject(
          {
            errorCatcherProps: props,
            error,
            info,
            errorCatcherId,
          }
        );
      }
      this.setState({ hasError: true, error, info });
    }

    componentWillReceiveProps() {
      this.setState({ hasError: false });
    }

    render() {
      if (this.state.hasError) {
        return (null);
      }
      return <WrappedComponent {...this.props} />;
    }
  };
};
