import $ from 'jquery';
import React from 'react' 
import ReactDOM from 'react-dom'
import AppInitialized from './AppInitialized';
const Office = window.Office;


Office.initialize = () => {
    $(document).ready(function () {

        Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged,
            function (eventArgs) {
                console.log(eventArgs);
                return("coucou")
            },
            function (result) {
                console.log(result);
            }
        );
        ReactDOM.render(
        <AppInitialized />,
       document.getElementById('root')
       );
// registerServiceWorker(););
    });
};