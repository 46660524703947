// This file is the compose tollbox.
// It contain HOC i.e function
import React from 'react';


import { compose, withProps, branch, mapProps } from 'recompose';
import { is, tryCatch } from 'ramda';
import ObjectInspector from 'react-object-inspector';
import { pickBy} from 'ramda'

// intl
import { injectIntl } from "react-intl";
import translations from "./translations.json";

// Recompose helper
const onFalsyPropSet = (propName, defaultValue) => (
  branch(props => (!props[propName]),
    withProps(() => ({ [propName]: defaultValue })))
);

export const removeProps = (propsToRemove =[]) => 
  mapProps(props => pickBy((propVal, propKey) => !propsToRemove.includes(propKey), props)
)

// Type Predicates
export const isString = is(String);

// Debug function

export const composeLog = (...args) => compose(
  ...(args.map((HOC, idx) => compose(
    HOC,
    logProps(`compose Log ${idx}`),
  )
  ))
)

export const logProps = (title = "default") => withProps((props) => {
  console.log(title, props);
  if (props.terminalApi && props.terminalApi.printLine) {
    props.terminalApi.printLine(
      <>
        <div>{title}</div>
        <ObjectInspector data={props} />
      </>
    );
  }
  return ({});
});

// eslint-disable-next-line
export const pause = withProps((props) => { debugger; });

// Helping Function
const nodeEnv = process.env.NODE_ENV;

// Screen Helping function
export const onMobile = () => navigator.userAgent.match(/Mobile/i);

export const xsAndUnder = () => window.screen.width <= 768;
export const smAndUnder = () => window.screen.width <= 992;
export const mdAndUnder = () => window.screen.width <= 1200;

const isDevelopmentEnv = nodeEnv === "development";

export const withDepracation = (predicate, warningMessage) => (
  withProps((props) => {
    if (isDevelopmentEnv && predicate(props)) {
      // eslint-disable-next-line
      console.error(warningMessage);
    }
    return ({});
  })
);


// Local Storage

// get in Local Storage
const getInLst = key => window.localStorage.getItem(key);

// Intl

const navigatorLanguage = (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.userLanguage;

const removeRegionCode = language => language.toLowerCase().split(/[_-]+/)[0];


const protectFormatMessage = formatMessageFn => (messageDescriptor, values) => {
  if (!messageDescriptor || !messageDescriptor.id) {
    return `missing translation`;
  }

  try {
    const translatedValue = formatMessageFn(messageDescriptor, values);
    if (!isString(translatedValue)) {
      throw "Translated Value should be a string ";
    }
    return formatMessageFn(messageDescriptor, values);
  } catch (error) {
    return `missing translation`;
  }
};

export const injectSafeIntl = compose(
  injectIntl,
  withProps(props => ({ 
    intl: { formatMessage: protectFormatMessage(props.intl.formatMessage) },
    language: removeRegionCode(navigatorLanguage),
  })),
  withProps(props => ({ messages: translations[props.language] || translations.en })),
);


export const Try = tryer => args => tryCatch(tryer, () => false)(args)