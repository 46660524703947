import React, { Component } from 'react';
import BehaviorList from './components/BehaviorList';
import Onboarding from './components/Onboarding';
import ProductChoice from './components/ProductChoice';
import SmartDeconnection from './components/SmartDexonnection';
import './App.css';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { defineMessages } from "react-intl";
import { injectSafeIntl, logProps } from './utils/recomposeToolbox';
import { handleApiResponse } from './utils/helpers';
import TooltipHostUnlessMobile from './utils/TooltipHostUnlessMobile';
import { withErrorBoundaryMsg } from './utils/WithErrorBoundary';
import { compose, setDisplayName, withProps, withHandlers, withState } from 'recompose'; 
import { dashboardURI, backendURI } from './constant';
import { DisplayIf } from './components/layout';

// https://docs.microsoft.com/fr-fr/graph/permissions-reference
const analyticsRequestedScopes = (
  "Calendars.Read MailboxSettings.Read User.Read Mail.Read offline_access"
)

const analyticsMinimumScopes = (
  "Calendars.Read MailboxSettings.Read Mail.Read"
)

// Requested scopes
const smartDeconnexionRequestedScopes = (
  "Mail.ReadWrite Calendars.Read MailboxSettings.ReadWrite User.Read offline_access"
)
const smartDeconnexionMinimumScopes = "Mail.ReadWrite MailboxSettings.ReadWrite"

const userHasAccessToAnalytics = currently_allowed_scopes => analyticsMinimumScopes.split(' ').every(scope => currently_allowed_scopes.includes(scope));
const userHasAccessToSmartDeconnexion = currently_allowed_scopes => smartDeconnexionMinimumScopes.split(' ').every(scope => currently_allowed_scopes.includes(scope));

const consentURL = (email, scope) => (
  `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?\
  client_id=15a9849e-dad7-44f1-abb6-ec70a9822043\
  &response_type=code\
  &redirect_uri=${backendURI}/synchronous_ms_personnal_access_grant\
  &response_mode=query\
  &scope=${scope}\
  &domain_hint=organizations\
  &login_hint=${email}`
);

const SmartDeconnexionToggle = (props) => {
  const { checked, handleOnToogleClick, tooltipContent } = props;
  return (
    <div id="toggle">
      <TooltipHostUnlessMobile styles={{ fontSize: '10px', width: '30px' }} content={tooltipContent} id="smartdeconnexion tooltip" calloutProps={{ gapSpace: 0 }}>
        <Toggle
          handle
          checked={checked}
          onText="Smart Deconnexion"
          offText="Smart Deconnexion"
          onChange={handleOnToogleClick}
        />
      </TooltipHostUnlessMobile>
    </div>
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: this.initialPage(),
      userNotFirstTime: props.userNotFirstTime,
      employeeStatus: this.props.employeeStatus,
    };
  }

  initialPage () {
    const currently_allowed_scopes = this.props.employeeStatus.currently_allowed_scopes;
    const { allowProductChoice } = this.props
    if (!this.props.userNotFirstTime && allowProductChoice && !userHasAccessToSmartDeconnexion(currently_allowed_scopes)) {
      return ("productsChoice");
    }
    return ("feedback");
  }

  followingDisplayPage() {
    const currently_allowed_scopes = this.props.employeeStatus.currently_allowed_scopes;
    const { allowProductChoice } = this.props
    if (!allowProductChoice) {
      return ("feedback")
    } else if (userHasAccessToSmartDeconnexion(currently_allowed_scopes)) {
      return ("smartDeconnexion")
    } else {

      return ("productsChoice")
    }
  }

  feedbackOnlySelect = () => {
    this.selectPage("feedback");
    global.Office.context.roamingSettings.set("userNotFirstTime", true);
    global.Office.context.roamingSettings.saveAsync();
  }

  readAndWriteSelect = () => {
    global.Office.context.roamingSettings.set("userNotFirstTime", true);
    global.Office.context.roamingSettings.saveAsync();
    this.selectPage("feedback");
    this.props.askMsScope(analyticsRequestedScopes)
  }

  handleSmartDeconnexionToggle = (e, turnon) => {
    if (turnon) {
      if (this.state.employeeStatus.smart_deconnexion_set) {
        this.props.requestClient.postSmartDeconnexionEnabled(this.props.email)(true)
          .then(handleApiResponse)
          .then(response => response.json())
          .then(response => this.setState({ employeeStatus: response }));
      } else {
        // The Smart deconexion is not set .
        this.selectPage("smartDeconnexion")
      }
    } else {
      this.props.requestClient.postSmartDeconnexionEnabled(this.props.email)(false)
        .then(handleApiResponse)
        .then(response => response.json())
        .then(response => this.setState({ employeeStatus: response }));
    }
  }

  saveDeconnexionSettings = (country, time_zone, working_hours, working_days, is_enabled_at_nights, is_enabled_at_weekends, is_enabled_at_vacations) => {
    this.props.requestClient
      .postDeconnexionSettings(this.props.email)(country, time_zone, working_hours, working_days, is_enabled_at_nights, is_enabled_at_weekends, is_enabled_at_vacations)
      .then(handleApiResponse)
      .then(response => response.json())
      .then(response => this.props.setDeconnexionSettings(response))
      .then(this.askSmartDeconnexionPermission)
    this.selectPage("feedback");
  }

  enableSmartDeconnexion = () => {
    this.selectPage("feedback")
    this.props.requestClient.postSmartDeconnexionEnabled(this.props.email)(true)
      .then(handleApiResponse)
      .then(response => response.json())
      .then(response => this.setState({
        employeeStatus: response, userNotFirstTime: true
      }));
  }

  askSmartDeconnexionPermission = () => {
    if (userHasAccessToSmartDeconnexion(this.props.employeeStatus.currently_allowed_scopes)) {
      // console.log("Scope already requested")
    } else {
      this.props.askMsScope(smartDeconnexionRequestedScopes)
      global.Office.context.roamingSettings.set("userNotFirstTime", true);
      global.Office.context.roamingSettings.saveAsync();
    }
  }

  handleSettingsClick = () => {
    this.selectPage(this.followingDisplayPage());
  }

  feedbackApp = (props) => {
    const { intl: { formatMessage } } = props;
    const {
      votes, behaviors, postVote, deleteVote, item, completeLocaleData, language, settings, messageSettings, handleDashboardBtnClick
    } = props;

    const {
      allowProductChoice
    } = props;

    const messages = defineMessages(completeLocaleData[language] || completeLocaleData.fr);

    const onBoardingComponent = (
      <div>
        <Onboarding {...this.props} messages={messages} />
      </div>
    );
    const onMobile = (navigator.userAgent.match(/Mobile/i));
    return (
      <div id="content">
        <div id={onMobile ? "content-header-reponsive" : "content-header"}>
          <SmartDeconnexionToggle 
            tooltipContent= {true ? (formatMessage(messages.smartdeconnexTooltip)) : formatMessage(messages.smartdeconnexTooltipNotAllowed)}
            checked={this.state.employeeStatus.smart_deconnexion_enabled}
            handleOnToogleClick={this.handleSmartDeconnexionToggle}
          />
          <TooltipHostUnlessMobile tooltipProps={{ style: { width: '130px', maxWidth: '150px', textAlign: 'center' } }} content={formatMessage(messages.magiclinkTooltip)} id="tooltip magiclink" calloutProps={{ gapSpace: 0 }}>
            <img
              id={onMobile ? "magiclink-responsive" : "magiclink"}
              className="onboarding-dashboard"
              src="../assets/speedometer5.png"
              alt="Dashboard"
              onClick={handleDashboardBtnClick}
            />
          </TooltipHostUnlessMobile>
          <DisplayIf display={allowProductChoice}>
            <TooltipHostUnlessMobile tooltipProps={{ style: { width: '130px', maxWidth: '150px', textAlign: 'center' } }} content={formatMessage(messages.settingslinkTooltip)} id="tooltip settingslink" calloutProps={{ gapSpace: 0 }}>
              <img id={onMobile ? "settingslink-responsive" : "settingslink"} src="../assets/settings1.png" alt="Dashboard" onClick={this.handleSettingsClick} />
            </TooltipHostUnlessMobile>
          </DisplayIf>
        </div>

        <div id={onMobile ? "content-main" : "content-main"} style={{ borderTop: "rgb(226, 226, 226) 1px solid", backgroundColor: "rgb(238, 238, 238)" }}>
          {(settings.get("userOnboard") === false || settings.get("userOnboard") === undefined) ? onBoardingComponent : null}
          <h1
            className="ms-font-l"
            style={{
              fontSize: "16px", paddingBottom: '10px', position: 'relative', textAlign: "center"
            }}
          >
            {formatMessage(messages.title)}
          </h1>
          <BehaviorList
            messageSettings={messageSettings}
            behaviors={behaviors}
            postVote={postVote}
            deleteVote={deleteVote}
            votes={votes}
            item={item}
          />
        </div>
      </div>
    );
  }

  selectPage = pageName => this.setState({ selectedPage: pageName })

  isPageSelected = pageName => this.state.selectedPage === pageName

  selectedProducts =  ()  => {
    const currently_allowed_scopes = this.props.employeeStatus.currently_allowed_scopes
    if (userHasAccessToAnalytics(currently_allowed_scopes)) {
      return (["feedback", "analytics"])
    } else if (userHasAccessToSmartDeconnexion(currently_allowed_scopes)) {
      return (["feedback", "analytics", "deconnexion"])
    } else {
      return (["feedback"])
  }}

  render() {
    const { isPageSelected } = this
    const { completeLocaleData, language } = this.props;
    const messages = defineMessages(completeLocaleData[language] || completeLocaleData.fr);
    return (
      <div>
        {isPageSelected("feedback") ? this.feedbackApp(this.props) : null }
        {isPageSelected("productsChoice") ? (

          <div>
            <ProductChoice
              requestClient={this.props.requestClient}
              feedbackOnlySelect={this.feedbackOnlySelect}
              readAndWriteSelect={this.readAndWriteSelect}
              selectSmartDeconnexion={() => this.selectPage("smartDeconnexion")}
              saveDeconnexionSettings={this.saveDeconnexionSettings}
              messages={messages}
              selectedProducts={this.selectedProducts()}
            />
          </div>
        ) : null}

        {isPageSelected("smartDeconnexion") ? (
          <div>
            <SmartDeconnection
              deconnexionSettings={this.props.deconnexionSettings}
              feedbackOnlySelect={this.feedbackOnlySelect}
              readAndWriteSelect={this.readAndWriteSelect}
              saveDeconnexionSettings={this.saveDeconnexionSettings}
              activateSmartDeconnexion={this.activateSmartDeconnexion}
              messages={messages}
              handleOnFirstPageBackClick={() => this.selectPage("feedback")}
            />
          </div>
        ) : null}
      </div>
    );
  }
};
export default setDisplayName("App")(compose(
  withErrorBoundaryMsg("App"),
  injectSafeIntl,
  withState("deconnexionSettings", "setDeconnexionSettings", props => props.employeeStatus),
  withProps(props => ({
    allowProductChoice: props.exchangeApiAvailable && props.employeeStatus.personal_grant_company_policy,
    magicLink: `${dashboardURI}/me#email=${props.employeeStatus.user_magic_link.email}&temporary_password=${props.employeeStatus.user_magic_link.temporary_password}`,
    askMsScope: scope => {global.Office.context.ui.displayDialogAsync(consentURL(props.email, scope), { height: 100, width: 66 }) },
  })),
  withHandlers({
    handleDashboardBtnClick: props => () => { global.window.open(props.magicLink); }
  }),
  logProps("app")
)(App));
