import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { addLocaleData, defineMessages, IntlProvider } from "react-intl";
import fr from "react-intl/locale-data/fr";
import en from "react-intl/locale-data/en";
import {
  compose, branch, withProps, renderComponent, withHandlers, renameProp, withState
} from 'recompose';
import App from './App';

import { injectSafeIntl, pause, removeProps, logProps, deCompose, composeLog } from './utils/recomposeToolbox';
import { withExchangeIdentityToken } from './components/withRailsCredentials';
import withBehaviors from './components/withBehaviors';
import withDomains from './components/withDomains';
import withVotes from './components/withVotes';
import FeedbackNotAllow from './components/FeedbackNotAllow';
import utilsFeedbackAllowed from './utils/utilsFeedbackAllowed';
import {
  fetchEmployeeStatus, handlePromiseResolution, Loading, fetchPromise, notifyIsApiAvailable
} from './utils/withFetchedData';
import { isExchangeApiAvailable, officeDatas } from './utils/exchange-utils';
import { withErrorBoundaryMsg } from './utils/WithErrorBoundary';
import { withConsole } from './HOC/withConsole';
import { displayConsole } from './constants';
import { backendURI } from './constant';
import { fetchFn } from './utils/helpers';
import { RequestFactory } from './utils/RequestFactory';

const officeSettingsHandler = (settings) => {
  const getter = key => settings.get(key);
  const setter = (key, value) => { settings.set(key, value); settings.saveAsync(); };
  return ({
    get: getter,
    set: setter,
  });
};

const roamingsSettingsValues = roamingSettings => ({
  userNotFirstTime: (roamingSettings.get("userNotFirstTime")),
});

const withIntlProvider = WrappedComponnent => props => (
  <IntlProvider locale={props.language} defaultLocale="fr">
    <WrappedComponnent {...props} />
  </IntlProvider>
);

const withOfficeJS = () => compose(
  withProps(props => ({ promise: global.Office.onReady() })),
  handlePromiseResolution(),
  branch((({ isLoading }) => isLoading), renderComponent(Loading)),
  withProps(() => ({ officeJS: global.Office })),
)

addLocaleData([...en, ...fr]);
export default compose(
    withErrorBoundaryMsg("Initialize"),
    withProps(() => ({ displayConsole })),
    //withConsole,
    withOfficeJS(),
    withProps(props => officeDatas(props.officeJS)),
    withProps(props => ({
      settingsHandler: officeSettingsHandler(props.settings),
    })),
    withProps(props => ({ ...roamingsSettingsValues(props.settings) })),
    withProps(() => ({ backendRootUrl: backendURI})),
    withExchangeIdentityToken(),
    fetchEmployeeStatus(),
    isExchangeApiAvailable(),
    branch((({ isLoading }) => isLoading), renderComponent(Loading)),
    withProps((props) => ({
      requestClient: new RequestFactory(
        props.email,
        props.token,
        'feedbackEmail',
        fetchFn,
        props.exchangeIdentityToken
      )
    })),
    notifyIsApiAvailable,
    withDomains,
    withBehaviors,
    withVotes,
    branch(({ behaviors, votes, completeLocaleData }) => { return (!behaviors || !votes || !completeLocaleData); },
      renderComponent(Loading)),
    withIntlProvider,
    injectSafeIntl,
    withProps(props => ({ messageSettings: new utilsFeedbackAllowed(props.email, props.item, props.itemClass, props.domains) })),
    branch(({ messageSettings }) => (false && messageSettings.isFeedbackNotAllowed()),
      renderComponent((props) => {
        const { intl: { formatMessage } } = props;
        const messages = defineMessages(props.completeLocaleData[props.language] || props.completeLocaleData.fr);
        const utils = new utilsFeedbackAllowed(props.email, props.item, props.itemClass, props.domains);
        return <FeedbackNotAllow message={utils.feedbackNotAllowedMessage(messages, formatMessage)} messages={messages} employeeStatus={props.employeeStatus} requestClient={props.requestClient} magicLink={props.magicLink} />;
      })),
    removeProps(["currentItemRestId", "debug", "exchangeAccessToken", "promise", "isLoading"]),
  )(App);
