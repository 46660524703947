import { compose, withProps} from 'recompose';
import {handlePromiseResolutionWithLoading } from '../utils/withFetchedData';

export const exchangeIdentityTokenPromise = (mailbox) => (
  new Promise((resolve, reject) => {
    mailbox.getUserIdentityTokenAsync(resolve, reject);
  }).then(response => response.value)
)

export const withExchangeIdentityToken = () => compose(
  withProps(({ mailbox }) => ({
    promise: exchangeIdentityTokenPromise(mailbox)
  })),
  handlePromiseResolutionWithLoading({ valueName: 'exchangeIdentityToken' }),
)
