import React, { Component } from 'react';
import './BehaviorList.css';
import { detect } from 'detect-browser';
import { Container, Row } from 'reactstrap';
import { compose, withProps } from 'recompose';
import { injectSafeIntl, logProps, Try } from '../utils/recomposeToolbox';
import Behavior from './Behavior';
import { withErrorBoundaryMsg } from '../utils/WithErrorBoundary';


const isPositiveBehavior = behavior => behavior.family === "positive";
const isNeutralBehavior = behavior => behavior.family === "neutral";
const isNegativeBehavior = behavior => behavior.family === "negative";


const isExternalBehavior = behavior => behavior.perimeter === "EXTERNAL";
const isInternalBehavior = behavior => behavior.perimeter === "INTERNAL";
const isMixedBehavior = behavior => behavior.perimeter === "MIXED";

const isMixedOrExternalBehavior = behavior => isMixedBehavior(behavior) || isExternalBehavior(behavior);
const isMixedOrInternalBehavior = behavior => isMixedBehavior(behavior) || isInternalBehavior(behavior);

const browser = detect()
const onMobile = !!(navigator.userAgent.match(/Mobile/i))

const WrapInOnbordingVoteDiv = (props) => {
  if (props.wrap) {
    return (
      <div className="onboarding-vote">
        {props.children}
      </div>
    );
  }
  return (props.children);
}

const WrapInContainerRow = (props) => {
  if (props.wrap) {
    return (
      <Container>
        <Row>
          {props.children}
        </Row>
      </Container>
    );
  }
  return (props.children);
}

const BehaviorList = compose(
  withProps(props =>{
    const { item, behaviors, messageSettings } = props
    console.log(behaviors)
    return ({
      behaviors: behaviors
        .filter(behavior => (item.itemType === (behavior.category === "meeting" ? "appointment" : behavior.category) ))
        .filter((messageSettings.conditions.externalEmail ? isMixedOrExternalBehavior : isMixedOrInternalBehavior))
    })
  }),
)((props) => {
  const { behaviors, language, votes } = props
  const positives = behaviors.filter(isPositiveBehavior)
  const neutrals = behaviors.filter(isNeutralBehavior)
  const negatives = behaviors.filter(isNegativeBehavior)
  return (
    <WrapInContainerRow 
      wrap={true}>
          <div
            id="behavior-list"
            className={onMobile ? "ms-Grid" : undefined}
            dir={onMobile ? "ltr" : undefined}
          >
            <div
              id="behavior-list"
              sm={onMobile ? { size: 6, order: 2, offset: 4 } : undefined}
            >
              <hr id={onMobile ? "hrStyle-responsive" : "hrStyle"} />
              <div className="first-behavior-headline box-flat-white">
                <img id="smiley" src="assets/positive.png" />
              </div>
              <div>
              { 
                positives.map((behavior, idx) => 
                    <WrapInOnbordingVoteDiv key={behavior.ref_name} wrap={idx == 0}>
                      <Behavior  
                          name={behavior["@option"].nameTranslator(language)}
                          id={behavior.id}
                          family={behavior.family}
                          description={behavior["@option"].descriptionTranslator(language)}
                          ref_name={behavior.ref_name}
                          vote_id={Try(() => votes.filter(vote => (vote.behavior_id === behavior.id))[0].vote_id)()}
                          postVote={props.postVote}
                          deleteVote={props.deleteVote}
                          onClick={props.handleClick} 
                        />
                  </WrapInOnbordingVoteDiv>
                )
              }
            </div>
  
              <hr id={onMobile ? "hrStyle-responsive" : "hrStyle"} />
              {
                  isNeutralBehavior.length > 0
                  && (
                  <div className="behavior-headline box-flat-white">
                    <img id="smiley" src="assets/neutral.png" />
                  </div>
                  )
              }   
              {
                neutrals.map(behavior =>             
                  <Behavior  
                    name={behavior["@option"].nameTranslator(language)}
                    id={behavior.id}
                    family={behavior.family}
                    description={behavior["@option"].descriptionTranslator(language)}
                    ref_name={behavior.ref_name}
                    vote_id={Try(() => votes.filter(vote => (vote.behavior_id === behavior.id))[0].vote_id)()}
                    postVote={props.postVote}
                    deleteVote={props.deleteVote}
                    onClick={props.handleClick} 
                  />
                )
              }
              <hr id={onMobile ? "hrStyle-responsive" : "hrStyle"} />
              <div className="behavior-headline box-flat-white">
                <img id="smiley" src="assets/negative.png" />
              </div>
              { 
                 negatives.map(behavior =>             
                  <Behavior  
                    name={behavior["@option"].nameTranslator(language)}
                    id={behavior.id}
                    family={behavior.family}
                    description={behavior["@option"].descriptionTranslator(language)}
                    ref_name={behavior.ref_name}
                    vote_id={Try(() => votes.filter(vote => (vote.behavior_id === behavior.id))[0].vote_id)()}
                    postVote={props.postVote}
                    deleteVote={props.deleteVote}
                    onClick={props.handleClick} 
                  />
                )
              }
            </div>
          </div>
    </WrapInContainerRow>
  )
})


export default compose(
  withErrorBoundaryMsg("BehaviorList"),
  injectSafeIntl,
)(BehaviorList);
