export const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE;
export const timezoneOptions = [
  { key: 'Europe/Paris', text: '(GMT+01:00) Europe/Paris' },
  { key: 'Europe/London', text: '(GMT+00:00) Europe/London' },
  { key: 'Europe/Amsterdam', text: '(GMT+01:00) Europe/Amsterdam' },
  { key: 'Europe/Brussels', text: '(GMT+01:00) Europe/Brussels' },
  { key: 'Europe/Copenhagen', text: '(GMT+01:00) Europe/Copenhagen' },
  { key: 'Europe/Paris', text: '(GMT+01:00) Europe/Paris' },
  { key: 'Europe/Rome', text: '(GMT+01:00) Europe/Rome' },
  { key: 'Europe/Vienna', text: '(GMT+01:00) Europe/Vienn"a' },
  { key: 'Europe/Zurich', text: '(GMT+01:00) Europe/Zürich' },
  { key: 'Europe/Helsinki', text: '(GMT+02:00) Europe/Helsinki' },
  { key: 'Africa/Cairo', text: '(GMT+02:00) Africa/Cairo' },
  { key: 'Europe/Moscow', text: '(GMT+03:00) Europe/Moscow' },
  { key: 'Europe/Istanbul', text: '(GMT+03:00) Europe/Istanbul' },
  { key: 'Asia/Riyadh', text: '(GMT+03:00) Asia/Riyadh' },
  { key: 'Asia/Muscat', text: '(GMT+04:00) Asia/Muscat' },
  { key: 'Asia/Hong_Kong', text: '(GMT+08:00) Asia/Hong Kong' },
  { key: 'Asia/Singapore', text: '(GMT+08:00) Asia/Singapore' },
  { key: 'Australia/Perth', text: '(GMT+08:00) Asia/Perth' },
  { key: 'Asia/Melbourne', text: '(GMT+10:00) Asia/Melbourne' },
  { key: 'Asia/Sydney', text: '(GMT+10:00) Asia/Sydney' },
  { key: 'Pacific/Auckland', text: '(GMT+12:00) Pacific/Auckland' },
  { key: 'America/Los_Angeles', text: '(GMT-08:00) America/Los Angeles' },
  { key: 'America/Denver', text: '(GMT-07:00) America/Denver' },
  { key: 'America/Chicago', text: '(GMT-6:00) America/Chicago' },
  { key: 'America/America/New_York', text: '(GMT-05:00) America/New York' },
];

export const countryOptions = [
  { key: 'FR', text: 'France' },
  { key: 'AF', text: 'Africa' },
  { key: 'AL', text: 'Algeria' },
  { key: 'AND', text: 'Andorra' },
  { key: 'AT', text: 'Australia' },
  { key: 'AT', text: 'Austria' },
  { key: 'B', text: 'Belgium' },
  { key: 'AD', text: 'Bosnia and Herzegovina' },
  { key: 'HR', text: 'Croatia' },
  { key: 'CZ', text: 'Czech Republik' },
  { key: 'DK', text: 'Denmark' },
  { key: 'EGY', text: 'Egypt' },
  { key: 'EM', text: 'Emirates' },
  { key: 'EUR', text: 'Europe' },
  { key: 'F', text: 'Finland' },
  { key: 'GB', text: 'Gibraltar' },
  { key: 'HK', text: 'Hong Kong' },
  { key: 'IR', text: 'Irland' },
  { key: 'IS', text: 'Israel' },
  { key: 'I', text: 'Italy' },
  { key: 'FL', text: 'Liechtenstein' },
  { key: 'L', text: 'Luxembourg' },
  { key: 'MC', text: 'Macedonia' },
  { key: 'M', text: 'Malta' },
  { key: 'NL', text: 'Netherlands' },
  { key: 'NZ', text: 'New Zealand' },
  { key: 'N', text: 'Norway' },
  { key: 'OM', text: 'Oman' },
  { key: 'RU', text: 'Russia' },
  { key: 'SA', text: 'Saudi Arabia' },
  { key: 'SG', text: 'Singapore' },
  { key: 'SL', text: 'Slowenia' },
  { key: 'E', text: 'Spain' },
  { key: 'CH', text: 'Switzerland' },
  { key: 'TK', text: 'Turkey' },
  { key: 'UK', text: 'United Kingdom' },
  { key: 'USA', text: 'United States of America' },
];
