import React, { Component } from 'react';
import { compose, lifecycle, withProps, withState, withHandlers } from 'recompose';
import { handleApiResponse, fetchFn } from '../utils/helpers';
import { logProps } from '../utils/recomposeToolbox';

const withVotes = (WrappedComponent) => {
  return class extends Component {
    state = {
      votes: undefined,
      error: undefined,
      apiError: false,
      item: this.props.mailbox.item,
      itemClass: this.props.mailbox.item.itemClass
    }

    componentDidMount() {
      const that = this
      try {
        global.Office.context.mailbox.addHandlerAsync(global.Office.EventType.ItemChanged,
          event => {
            try {
              that.load()
            } catch {
              console.log("Can't reload vote on Item change")
            }
          },
        )
      } catch {
        console.log("Can't Add Item change listener")
      }
      this.load()
    }
    
    load() {
      const { mailbox, requestClient , settings } = this.props;

      const getVotes = () => {
        mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
          if (asyncResult.status === global.Office.AsyncResultStatus.Failed) {
          } else {
            const votes = asyncResult.value.get("votes");
            if (votes) {
              this.setState({ votes });
            } else if (mailbox.item.itemType === "message") {
              requestClient
                .getVotes(this.props.base64InternetMessageId)()
                .then(handleApiResponse)
                .then(response => response.json())
                .then((votes) => {
                  return mapVotesToBehaviors(votes);
                })
                .then((votes) => {
                  this.setState({ votes });
                  return votes;
                })
                .then((votes) => {
                  this.storeInCustomProperties("votes")(votes);
                })
                .catch(() => this.setState({ apiError: true }));
            } else {
              const meetingJson = {
                end: mailbox.item.end.toUTCString(),
                organizer: mailbox.item.organizer.emailAddress,
                required_attendees: mailbox.item.requiredAttendees.map(attendee => attendee.emailAddress),
                start: mailbox.item.start.toUTCString()
              };
              requestClient
                .postVotesMeeting()(meetingJson)
                .then(handleApiResponse)
                .then(response => response.json())
                .then((votes) => {
                  return mapVotesToBehaviors(votes);
                })
                .then((votes) => {
                  this.setState({ votes });
                  return votes;
                })
                .then(this.storeInCustomProperties("votes"))
                .catch(() => this.setState({ apiError: true }));
            }
          }
        });
      };


      getVotes();

      const mapVotesToBehaviors = (data) => {
        return (
          data.map(vote => ({
            behavior_id: vote.behavior_id,
            vote_id: vote.id
          }))
        );
      };
    }

    storeInCustomProperties = name => (value) => {
      this.props.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
        if (asyncResult.status === global.Office.AsyncResultStatus.Failed) {
        } else {
          asyncResult.value.set(name, value);
          asyncResult.value.saveAsync();
        }
      });
      return value;
    }

    postVote = (behavior_id) => {
      const { requestClient } = this.props;
      const { mailbox } = this.props;

      if (mailbox.item.itemType === "message") {
        const emailJson = {
          cc: mailbox.item.cc.map(receiver => receiver.emailAddress),
          conversation_id: mailbox.item.conversationId,
          date: mailbox.item.dateTimeCreated.toUTCString(),
          from: mailbox.item.from.emailAddress,
          mailbox_message_id: mailbox.item.itemId,
          internet_message_id: mailbox.item.internetMessageId,
          sender: mailbox.item.sender.emailAddress,
          to: mailbox.item.to.map(sender => sender.emailAddress),
        };

        requestClient
          .postVote()(behavior_id, emailJson)
          .then(handleApiResponse)
          .then(response => response.json())
          .then(this.handlePostVoteResponse)
          .then(uniqueVotes => this.setState({ votes: uniqueVotes }))
          .catch(error => this.setState({ apiError: true, error }));
      } else {
        const meetingJson = {
          created: mailbox.item.dateTimeCreated,
          end: mailbox.item.end.toUTCString(),
          mailbox_id: mailbox.item.id,
          organizer: mailbox.item.organizer.emailAddress,
          optional_attendees: mailbox.item.optionalAttendees.map(attendee => attendee.emailAddress),
          required_attendees: mailbox.item.requiredAttendees.map(attendee => attendee.emailAddress),
          start: mailbox.item.start.toUTCString()
        };

        requestClient
          .postVoteMeeting()(behavior_id, meetingJson)
          .then(handleApiResponse)
          .then(response => response.json())
          .then(this.handlePostVoteResponse)
          .then(uniqueVotes => this.setState({ votes: uniqueVotes }))
          .catch(error => this.setState({ apiError: true, error }));
      }
    }

    deleteVote = (vote_id) => {
      const { votes } = this.state;
      const { requestClient}  = this.props

      requestClient
        .deleteVote(vote_id)()
        .then(handleApiResponse)
        .catch(() => this.setState({ apiError: true }));

      const currentVotes = votes;
      let index;
      currentVotes.forEach((voteObj, i) => {
        if (voteObj.vote_id === vote_id) {
          index = i;
        }
      });
      currentVotes.splice(index, 1);
      this.storeInCustomProperties("votes")(currentVotes);
      this.setState({ votes: currentVotes });
    }

    handlePostVoteResponse = (data) => {
      const { votes } = this.state;

      const vote = {
        behavior_id: data.behavior_id,
        vote_id: data.id,
      };
      const currentVotes = votes;
      const alreadyVoted = currentVotes.some(v => (v.behavior_id === vote.behavior_id));
      const unique = (alreadyVoted) ? currentVotes : [...currentVotes, vote];
      this.storeInCustomProperties("votes")(unique);
      return unique;
    }

    render() {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          postVote={this.postVote}
          deleteVote={this.deleteVote}
        />
      );
    }
  };
};


export default compose(
  logProps("WithVotes"),
  withProps(props => ({
    base64InternetMessageId: window.btoa(props.internetMessageId),
  })),
  withVotes,
);
