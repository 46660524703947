import React, { Component } from 'react';
import {
  Dropdown,
} from 'office-ui-fabric-react/lib/Dropdown';
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import TooltipHostUnlessMobile from '../utils/TooltipHostUnlessMobile';
import DaysPicker from './DaysPicker';
import './SmartDeconnextion.css';
import { injectSafeIntl, logProps } from '../utils/recomposeToolbox';
import { withErrorBoundaryMsg } from '../utils/WithErrorBoundary';
import { compose } from 'recompose';
import { timezoneOptions, countryOptions } from '../constants';

const to2DigitsString = number => (number < 10) ? `0${number}` : `${number}`

const fromHourFloatToString = (hourFloat) => `\
  ${to2DigitsString(Math.floor(hourFloat))}\
  :\
  ${to2DigitsString(Math.round((hourFloat % 1) * 60))}\
`


const clean = obj =>
  Object.keys(obj).reduce((acc, key) => {
    const temp = (obj[key] === undefined || obj[key] === null) ? {} : { [key]: obj[key] }
    return ({ ...acc, ...temp })
  }, {})

class SmartDeconnexion extends Component {
  constructor(props) {
    super(props);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.handleOnClickSave = this.handleOnClickSave.bind(this);

      const defaultWorkingTime = {
        "1": [9, 18],
        "2": [9, 18],
        "3": [9, 18],
        "4": [9, 18],
        "5": [9, 18],
      }


    const toInt = elm => parseInt(elm, 10)
    const { 
      country = "FR", 
      working_time = defaultWorkingTime,
      is_deconnexion_enabled_at_nights = true, 
      is_deconnexion_enabled_at_weekends = true, 
      is_deconnexion_enabled_at_vacations = true,
      time_zone = 'Europe/Paris', 
    
    } = clean(props.deconnexionSettings)
  
    const workingDays = Object.keys(working_time).map(toInt)
    const startHours = working_time[Object.keys(working_time)[0]][0] || 9
    const endHours = working_time[Object.keys(working_time)[0]][1] || 18

    this.state = {
      percentComplete: 0.2,
      countrytimezonearrow: false,
      dayshoursarrow: true,
      enablearrow: true,
      messageBarVisibleHours: false,
      messageBarVisibleTimezone: false,

      country: country,
      timezone: time_zone,
      workingDays: workingDays,
      startHours: startHours ,
      endHours: endHours,
      nights: is_deconnexion_enabled_at_nights,
      weekends: is_deconnexion_enabled_at_weekends,
      vacations: is_deconnexion_enabled_at_vacations,
    };
  }

  componentDidMount() {
    this.startProgressDemo();
  }


    startProgressDemo = () => {
      this.setState({ percentComplete: 0.2 });
    }

    onCheckboxChange = () => {
      // console.log("ischecked")
    }

    setTimezone = (event, item) => {
      // console.log('here is the things updating...' + item.key + ' ' + item.text + ' ' + item.selected);
      // console.log("event", event, "item", item)
      this.setState({ timezone: item.key });
    }

    setCountry = (event, item) => {
      // console.log('here is the things updating...COUNTRY #####' + item.key + ' ' + item.text + ' ' + item.selected);
      // console.log("event", event, "item", item)
      this.setState({ country: item.key });
    }

    setWorkingDays = (workingDays) => {
      this.setState({ workingDays });
    }

  changeWorkingDays = (value, day) => {
    value ? this.addWorkingDays(day) : this.removeWorkingDays(day);
  }

  addWorkingDays = (day) => {
    const workingDays = [...this.state.workingDays, day];
    this.setWorkingDays(workingDays);
    this.setState({ workingDays });
  }

  removeWorkingDays = (remove_day) => {
    const workingDays = this.state.workingDays.filter(day => remove_day !== day);
    this.setWorkingDays(workingDays);
    this.setState({ workingDays });
  }


    setStartHours = (event, item) => {
      // console.log('here is the things updating...' + item + ' ' + item.text + ' ' + item.selected);


      const together = item.replace(/:/g, '');
      const hour = together.charAt(0) + together.charAt(1);
      const rawMinutes = ((together.charAt(2) + together.charAt(3)) / 60).toString();
      const rawstartHour = (`${hour}.${rawMinutes.charAt(2)}${rawMinutes.charAt(3)}`).toString();
      const startHour = parseFloat(rawstartHour);
      this.setState({ startHours: startHour });
    }

    setEndHours = (event, item) => {
      // console.log('here is the things updating...' + item + ' ' + item.text + ' ' + item.selected);
      const together = item.replace(/:/g, '');
      const hour = together.charAt(0) + together.charAt(1);
      const rawMinutes = ((together.charAt(2) + together.charAt(3)) / 60).toString();
      const rawstartHour = (`${hour}.${rawMinutes.charAt(2)}${rawMinutes.charAt(3)}`).toString();
      const endHour = parseFloat(rawstartHour);
      this.setState({ endHours: endHour });
    }

    setEnableAtNights = (event, value) => {
      this.setState({ nights: value });
    }

    setEnableAtWeekends = (event, value) => {
      this.setState({ weekends: value });
    }

    setEnableAtVacations = (event, value) => {
      this.setState({ vacations: value });
    }

    timezoneClick = () => {
      if (this.state.country && this.state.timezone) {
        this.setState({ countrytimezonearrow: true, dayshoursarrow: false, percentComplete: 0.5 });
      } else {
        this.setState({ messageBarVisibleTimezone: true });
      }
    }

    timezoneClickBack = () => {
      this.setState({ countrytimezonearrow: true, dayshoursarrow: false, percentComplete: 0.2 });
    }

    daysandhoursClick = (input, value) => {
      if (this.state.startHours && this.state.endHours) {
        this.setState({ dayshoursarrow: true, enablearrow: false, percentComplete: 0.9 });
      } else {
        this.setState({ messageBarVisibleHours: true });
      }
    }

    daysandhoursClickBack = () => {
      // console.log("hello")
      this.setState({
        messageBarVisibleTimezone: false, countrytimezonearrow: false, dayshoursarrow: true, percentComplete: 0.2
      });
    }

    enableClick = () => {
      this.setState({ dayshoursarrow: true, enablearrow: true });
    }

    enableClickBack = () => {
      this.setState({
        messageBarVisibleHours: false, dayshoursarrow: false, enablearrow: true, percentComplete: 0.5
      });
    }

    classCssCountryTimeZone = () => {
      const countrytimezone = "countrytimezone";
      const isClicked = this.state.countrytimezonearrow ? "clickedCountry" : "back";
      return `${countrytimezone} ${isClicked}`;
    }

    classCssDaysAndHours = () => {
      const daysandhours = "daysandhours";
      const isClicked = this.state.dayshoursarrow ? "clickedDays" : "back";
      return `${daysandhours} ${isClicked}`;
    }

    classCssEnable = () => {
      const enable = "enable";
      const isClicked = this.state.enablearrow ? "clickedEnable" : "back";
      return `${enable} ${isClicked}`;
    }

    handleOnClickSave = () => {
      // console.log("handleOnClickSave in APP")
      const {
        country, timezone, startHours, endHours, workingDays, nights, weekends, vacations
      } = this.state;
      this.props.saveDeconnexionSettings(country, timezone, [startHours, endHours], workingDays, nights, weekends, vacations);
    }

    render() {
      const { percentComplete } = this.state;
      const { messages } = this.props;
      const { intl: { formatMessage } } = this.props;

      const { startHours, endHours} = this.state;
      const { handleOnFirstPageBackClick } = this.props


      // console.log(this.props)
      // console.log(this.state)

      const MessageHours = (
        <MessageBar>
          {formatMessage(messages.messageBarHours)}
        </MessageBar>
      );

      const MessageTimezone = (
        <MessageBar>
          {formatMessage(messages.messageBarTimezone)}
        </MessageBar>
      );
      return (
        <div id="smartdeconnextion">
          <div id="smart-dnx-headline">
            <p className="ms-font-l" style={{ textAlign: 'center' }}>
              {' '}
              {formatMessage(messages.smartDeconnexionHeadline)}
              {' '}

            </p>
          </div>

          <div className={this.classCssCountryTimeZone()}>
            <div>
              <div className="ms-ComboBoxCustomStyledExample">
                <Dropdown
                  onChange={this.setCountry}
                  label={formatMessage(messages.country)}
                  id="country"
                  ariaLabel="Basic dropdown example"
                  selectedKey={this.state.country}
                  options={countryOptions}
                />

              </div>
              <div className="ms-ComboBoxCustomStyledExample">
                <Dropdown
                  onChange={this.setTimezone}
                  label={formatMessage(messages.timezone)}
                  id="country"
                  selectedKey={this.state.timezone}
                  ariaLabel="Basic dropdown example"
                  options={timezoneOptions}
                  componentRef={this._basicDropdown}
                />

              </div>

              {this.state.messageBarVisibleTimezone ? MessageTimezone : null}

            </div>

            <div id="arrowProgress">
              <img id="arrowBack" onClick={handleOnFirstPageBackClick} src="../../assets/productchoice/arrow_back.png" />
              <img id="arrowContinue" onClick={this.timezoneClick} src="../../assets/productchoice/arrow.png" />
              <ProgressIndicator percentComplete={percentComplete} />
            </div>

          </div>

          <div className={this.classCssDaysAndHours()}>

            <DaysPicker
              props={this.props}
              messages={messages}
              workingDays={this.state.workingDays}
              handleWorkingDayChange={this.changeWorkingDays}
            />

            <MaskedTextField
              className="ms-font-l"
              label={formatMessage(messages.startTime)}
              mask="99:99"
              value={fromHourFloatToString(startHours)}
              onChange={this.setStartHours}
            />
            <MaskedTextField
              className="ms-font-l"
              label={formatMessage(messages.endTime)}
              mask="99:99"
              value={fromHourFloatToString(endHours)}
              onChange={this.setEndHours}
            />

            {this.state.messageBarVisibleHours ? MessageHours : null}

            <div id="arrowProgress">
              <img id="arrowBack" onClick={this.daysandhoursClickBack} src="../../assets/productchoice/arrow_back.png" />
              <img id="arrowContinue" onClick={this.daysandhoursClick} src="../../assets/productchoice/arrow.png" />
              <ProgressIndicator id="progressIndicator" ProgressIndicatorStyleProps={{ backgroundColor: '#f7ae04' }} percentComplete={percentComplete} />
            </div>
          </div>

          <div className={this.classCssEnable()}>
            <div>
              <TooltipHostUnlessMobile className="ms-font-l" content={formatMessage(messages.smartdeconnexEnableNightsTooltip)} calloutProps={{ gapSpace: 0 }}>
                <Toggle
                  className="ms-font-l"
                  label={formatMessage(messages.enableNight)}
                  onText="On"
                  offText="Off"
                  checked={this.state.nights}
                  onChange={this.setEnableAtNights}
                />
              </TooltipHostUnlessMobile>

              <TooltipHostUnlessMobile className="ms-font-l" content={formatMessage(messages.smartdeconnexEnableVacationsTooltip)}  calloutProps={{ gapSpace: 0 }}>
                <Toggle
                  className="ms-font-l"
                  label={formatMessage(messages.enableWeekends)}
                  onText="On"
                  offText="Off"
                  checked={this.state.weekends}
                  onChange={this.setEnableAtWeekends}
                />
              </TooltipHostUnlessMobile>

              <TooltipHostUnlessMobile className="ms-font-l" content={formatMessage(messages.smartdeconnexEnableWeekendsTooltip)} calloutProps={{ gapSpace: 0 }}>
                <Toggle
                  className="ms-font-l"
                  label={formatMessage(messages.enableVacation)}
                  onText="On"
                  offText="Off"
                  checked={this.state.vacations}
                  onChange={this.setEnableAtVacations}
                />
              </TooltipHostUnlessMobile>
            </div>

            <div id="arrowProgress">
              <img id="arrowBack" onClick={this.enableClickBack} src="../../assets/productchoice/arrow_back.png" />
              <img id="arrowContinue" onClick={this.handleOnClickSave} src="../../assets/productchoice/arrow.png" />
              <ProgressIndicator id="progressIndicator" percentComplete={percentComplete} />
            </div>

          </div>

        </div>

      );
    }
}

export default compose(
  withErrorBoundaryMsg("SmartDeconexion"),
  injectSafeIntl,
  logProps("SD")
)(SmartDeconnexion);
