import React, { Component } from 'react';
import Terminal from 'terminal-in-react';
import ObjectInspector from 'react-object-inspector';
import PluginBase from 'terminal-in-react/lib/js/components/Plugin';

import { compose, withState, withHandlers } from 'recompose';
import { DisplayIf } from '../components/layout';
import { COMMIT_REF } from '../constant';

const getBindingPLugin = apiCallback => class BindingPLugin extends PluginBase {
    static displayName = 'BindingPLugin';

    static version = '0.0.1';

    constructor(api, config) {
      super(api, config);
      apiCallback(api);
    }
};

// The following code is not Idiomatic React, and have been created to get the Terminal API of therminal componnent.
// Please not take it as an example

const addConsole = WrappedComponnent => (
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        terminalApi: undefined
      };
    }

        printJsObject = (object) => {
          const { terminalApi } = this.state;
          if (terminalApi && terminalApi.printLine) {
            terminalApi.printLine(
                    <>
                      <ObjectInspector data={object} />
                    </>
            );
          }
        }

        render() {
          const { displayConsole } = this.props;
          const { printJsObject } = this;
          return (
                <>
                  <WrappedComponnent
                    setShowTerminal={this.setShowTerminal}
                    console={{
                      printJsObject
                    }}
                    {...this.state}
                    {...this.props}
                  />
                  <DisplayIf  display= {displayConsole} >
                    
                    <div style={{ display: 'inherit' }}>
                      <div style={{ height: "100vh" }} />
                      <Terminal
                        color="green"
                        backgroundColor="black"
                        hideTopBar
                        hide
                        allowTabs={false}
                        style={{ fontWeight: "bold", fontSize: "1em" }}
                        commands={{
                          // eslint-disable-next-line
                          exec: args => console.log(args)
                        }}
                        descriptions={{
                          'open-google': 'opens google.com',
                          showmsg: 'shows a message',
                          alert: 'alert',
                          popup: 'alert'
                        }}
                        plugins={[getBindingPLugin((api) => { this.setState({ terminalApi: api }); })]}
                        msg={`Welcome In the Mailoop add in Console ;-) Commit Ref : ${COMMIT_REF}`}
                        />
                    </div>
                  </DisplayIf>
                </>
          );
        }
  }
);

export const withConsole = compose(
//  withState("displayConsole", "setDisplayConsole", false),
  // withHandlers({
  //   toogleDisplayConsole: props => () => props.setDisplayConsole(!props.displayConsole)
  // }),
  addConsole,
);
