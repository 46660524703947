import React, { Component } from 'react';
import { injectSafeIntl, logProps } from '../utils/recomposeToolbox';
import './ProductChoice.css';
import SmartDexonnection from './SmartDexonnection';
import TooltipHostUnlessMobile from '../utils/TooltipHostUnlessMobile';
import { compose } from 'recompose';

export const  ProductChoice  = props => {
  const { messages } = props;
  const { intl: { formatMessage } } = props;
  const { selectSmartDeconnexion, selectedProducts} = props
    return (
      <div>
      <div id="productchoice">
        <div id="headline">
          <p className="ms-font-l" style={{ fontSize: "20px", padding: '0px' }}>
            {formatMessage(messages.productChoiceHeadline)}
            {' '}

          </p>
        </div>
        <div>

          <hr />

          <div id="feedback360" 
          className={selectedProducts.includes("feedback")? "active-product" : "" }
          onClick={props.feedbackOnlySelect}>
            <p className="ms-font-l" style={{ fontSize: "14px", padding: '0px' }}>
              {' '}
              {formatMessage(messages.productChoiceFeedback360)}
              {' '}
            </p>
            <img id="arrow" src="../../assets/productchoice/arrow_orange.png" />
          </div>

          <TooltipHostUnlessMobile content={formatMessage(messages.productsChoiceFeedbacksTooltip)} style={{ width: '200px' }} calloutProps={{ gapSpace: 0 }}>
            <img id="evolution_icon" src="../../assets/productchoice/feedback360.png" />
          </TooltipHostUnlessMobile>
        </div>

        <div>
            <div id="analytics" onClick={selectedProducts.includes("analytics") ? props.feedbackOnlySelect : props.readAndWriteSelect}
              className={selectedProducts.includes("analytics") ? "active-product" : ""}
          >
            <p className="ms-font-l" style={{ fontSize: "14px", padding: '0px' }}>
              {' '}
              {formatMessage(messages.productChoiceAnalystics)}
              {' '}
            </p>
            <img id="arrowBottom" src="../../assets/productchoice/arrow_orange.png" />
          </div>
            <TooltipHostUnlessMobile content={formatMessage(messages.productsChoiceAnalyticsTooltip)} style={{ width: '200px' }}  calloutProps={{ gapSpace: 0 }}>
            <img id="evolution_icon" src="../../assets/productchoice/analytics.png" />
          </TooltipHostUnlessMobile>
        </div>


        <div>
            <div id="deconnection" onClick={selectSmartDeconnexion}
              className={selectedProducts.includes("deconnexion") ? "active-product" : ""} 
            >
            <p className="ms-font-l" style={{ fontSize: "14px", padding: '0px' }}>
              {' '}
              {formatMessage(messages.productChoiceDeconnexion)}
              {' '}
            </p>
            <img id="arrowBottom" src="../../assets/productchoice/arrow_orange.png" />
          </div>
            <TooltipHostUnlessMobile content={formatMessage(messages.productsChoiceDeconnexionTooltip)}  style={{ width: '200px' }} calloutProps={{ gapSpace: 0 }}>
            <img id="evolution_icon" src="../../assets/productchoice/smartDeconnexion.png" />
          </TooltipHostUnlessMobile>
        </div>
      </div>

      </div>


    );
}

export default compose(
  injectSafeIntl,
)(ProductChoice);
