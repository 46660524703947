export const fetchFn = header => method => endpoint => (payload) => {
  const headers = new Headers(header);
  const requestOptions = (method === 'get')
    ? {
      method,
      headers
    }
    : {
      method,
      headers,
      body: JSON.stringify(payload)
    };
  const request = new Request(endpoint, requestOptions);
  return fetch(request);
};

export const handleApiResponse = (response) => {
  if (response.status > 199 && response.status < 400) {
    return response;
  }
  throw `Api Error status : ${response.status}`;
};
