import React from 'react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';


const TooltipHostUnlessMobile = (props) => {
  if (navigator.userAgent.match(/Mobile/i)) {
    return props.children;
  }
  return (
    <TooltipHost {...props}>
      {props.children}
    </TooltipHost>

  );
};

export default TooltipHostUnlessMobile;
