import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import './FeedbackNotAllow.css';
import { detect } from 'detect-browser';
import { injectSafeIntl } from '../utils/recomposeToolbox';
import { handleApiResponse } from '../utils/helpers';
import TooltipHostUnlessMobile from '../utils/TooltipHostUnlessMobile';

class FeedbackNotAllow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeStatus: this.props.employeeStatus
    };
  }

    handleSmartDeconnexionToggle = (e, turnon) => {
      if (turnon) {
        if (this.state.employeeStatus.smart_deconnexion_set) {
          this.props.requestClient.postSmartDeconnexionEnabled(this.props.email)(true)
            .then(handleApiResponse)
            .then(response => response.json())
            .then(response => this.setState({ employeeStatus: response }));
        } else {
          this.props.requestClient.postSmartDeconnexionEnabled(this.props.email)(false);
          this.setState({ smartConnexionPage: true, feedbackPage: false });
        }
      } else {
        this.props.requestClient.postSmartDeconnexionEnabled(this.props.email)(false)
          .then(handleApiResponse)
          .then(response => response.json())
          .then(response => this.setState({ employeeStatus: response }));
      }
    }

    render() {
      const { message, messages } = this.props;
      const { intl: { formatMessage } } = this.props;
      const browser = detect();
      const { settings } = this.props;

      const SmartDeconnexionToggle = (allowed) => {
        const content = allowed ? formatMessage(messages.smartdeconnexTooltip) : formatMessage(messages.smartdeconnexTooltipNotAllowed);
        const disabled = !allowed;
        return (
          <div id="toggle">
            <TooltipHostUnlessMobile styles={{ fontSize: '10px', width: '30px' }} content={content} id="smartdeconnexion tooltip" calloutProps={{ gapSpace: 0 }}>
              <Toggle
                checked={this.state.employeeStatus.smart_deconnexion_enabled}
                disabled={disabled}
                onText="Smart Deconnexion"
                offText="Smart Deconnexion"
                onChange={this.handleSmartDeconnexionToggle}
              />
            </TooltipHostUnlessMobile>
          </div>);
      };

      if (navigator.userAgent.match(/Mobile/i)) {
        return (
          <div>
            <Container>
              <Row type="flex" justify="space-around">
                <div id="content">
                  <Col sm={{ size: 6, order: 3, offset: 10 }} xs={{ size: 'auto', offset: 1 }}>
                    <div id="content-header-reponsive">
                      {SmartDeconnexionToggle(this.state.employeeStatus.personal_grant_company_policy)}
                      <img id="settingslink-responsive" src="../assets/settings1.png" alt="Dashboard" onClick={() => global.window.open(this.props.magicLink)} />
                      <img
                        id="magiclinkFeedbackNotAllowed-responsive"
                        className="onboarding-dashboard"
                        src="../assets/speedometer5.png"
                        alt="Dashboard"
                        onClick={() => global.window.open(this.props.magicLink)}
                      />
                    </div>
                  </Col>
                  <div id="content-main-responsive" style={{ borderTop: "rgb(226, 226, 226) 1px solid", backgroundColor: "rgb(238, 238, 238)" }}>
                    <h1
                      className="ms-font-l"
                      style={{
                        fontSize: "16px", padding: '10px', position: 'relative', textAlign: "center"
                      }}
                    >
                      {formatMessage(messages.title)}
                    </h1>
                  </div>
                </div>
              </Row>
            </Container>

            <div id="nofeedbackcss">
              <p>
                {' '}
                {message}
                {' '}
              </p>
              <img id="nofeedback" src="../assets/nofeedback.png" alt="Not Allowed" />

            </div>
          </div>
        );
      } if (browser.name === "firefox" || "chrome" || "safari" || "ie" || "edge") {
        return (
          <div>
            <Container>
              <Row type="flex" justify="space-around">
                <div id="content">
                  <Col sm={{ size: 6, order: 3, offset: 10 }} xs={{ size: 'auto', offset: 1 }}>
                    <div id="content-header">
                      {SmartDeconnexionToggle(this.state.employeeStatus.personal_grant_company_policy)}
                      <img
                        id="magiclinkFeedbackNotAllowed"
                        className="onboarding-dashboard"
                        src="../assets/speedometer5.png"
                        alt="Dashboard"
                        onClick={() => global.window.open(this.props.magicLink, '_blank')}
                      />
                      <img id="settingslink" src="../assets/settings1.png" alt="Dashboard" onClick={() => global.window.open(this.props.magicLink)} />
                    </div>
                  </Col>
                  <div id="content-main" style={{ borderTop: "rgb(226, 226, 226) 1px solid", backgroundColor: "rgb(238, 238, 238)" }}>
                    <h1
                      className="ms-font-l"
                      style={{
                        fontSize: "16px", padding: '10px', position: 'relative', textAlign: "center"
                      }}
                    >
                      {formatMessage(messages.title)}
                    </h1>
                  </div>
                </div>
              </Row>
            </Container>
            <div id="content-main" style={{ borderTop: "rgb(226, 226, 226) 1px solid", backgroundColor: "rgb(238, 238, 238)" }}>
              <div id="nofeedbackcss">
                <p>
                  {' '}
                  {message}
                  {' '}
                </p>
                <img id="nofeedback" src="../assets/nofeedback.png" alt="Not Allowed" />
              </div>
            </div>
          </div>
        );
      }
    }
}

export default injectSafeIntl(FeedbackNotAllow);
